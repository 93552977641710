<template>
  <div class="about">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"
      :backgroundPicRwd="BannerKvData.backgroundPicRwd"
      :bgcolor="'#fff'"/>

    <section id="top_point">
      <div class="container w1000">
        <div class="inner">
          <div class="item">
            <div>
              <p class="small txt-white">Humanity</p>
              <h6 class="txt-white">{{$t('humanity')}}</h6>
            </div>
          </div>
          <div class="item">
            <div>
              <p class="small txt-white">Technology</p>
              <h6 class="txt-white">{{$t('technology')}}</h6>
            </div>
          </div>
          <div class="item">
            <div>
              <p class="small txt-white">Care</p>
              <h6 class="txt-white">{{$t('care')}}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="docter_total">
      <div class="w800 txt-center">
        <!-- <h3 class="eng">NUWA DOCTOR</h3> -->
        <h5 class="txt-bold">{{$t('nuwaTeams')}} <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower"></h5>
        <br>
        <p class="txt-dark_gray small">
          {{$t('nuwaTeamsContent')}}
        </p>
      </div>
      <div class="container w1400">
        <div class="item">
          <div class="pic">
            <figure
              :style="{backgroundImage: `url(${require('@/assets/img/About_NUWA/service_1_new.jpg')})`}">
            </figure>
          </div>
          <div class="text txt-center">
            <div>
              <h5 class="txt-bold">
                NUWA FERTILITY
              </h5>
              <p v-if="getLanguage !== 'en-US'">
                {{$t('nuwaIvf')}}
              </p>

              <p v-else v-html="npsp"></p>

              <p class="info small">
                {{$t('ivfContent')}}
              </p>
            </div>

            <div class="btn btn-link">
              <router-link to="/staff/procreation/p1">{{$t('introTeams')}}</router-link>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="pic">
            <figure
              :style="{backgroundImage: `url(${require('@/assets/img/About_NUWA/service_2_new.jpg')})`}">
            </figure>
          </div>
          <div class="text txt-center">
            <div>
              <h5 class="txt-bold">
                NUWA TCM CENTER
              </h5>
              <p v-if="getLanguage !== 'en-US'">
                {{$t('tcmTitle')}}
              </p>

              <p v-else v-html="npsp"></p>

              <p class="info small">
                {{$t('tcmContent')}}
              </p>
            </div>

            <div class="btn btn-link">
              <router-link to="/staff/traditional/t1">{{$t('introTeams')}}</router-link>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="pic">
            <figure
              :style="{backgroundImage: `url(${require('@/assets/img/About_NUWA/service_3_new.jpg')})`}">
            </figure>
          </div>
          <div class="text txt-center">
            <div>
              <h5 class="txt-bold">
                NUWA LAB
              </h5>
              <p v-if="getLanguage !== 'en-US'">
                {{$t('nuwaLabs')}}
              </p>

              <p v-else v-html="npsp"></p>

              <p class="info small">
                {{$t('aboutLabs')}}
              </p>
            </div>

            <div class="btn btn-link">
              <router-link to="/staff/labs/l1">{{$t('introTeams')}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="advantage">
      <div class="w800 txt-center">
        <!-- <h3 class="eng">OUR ADVANTAGE</h3> -->
        <h5 class="txt-bold">{{$t('advantage')}} <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower"></h5>
        <br>
        <p class="txt-dark_gray small">
          {{$t('advantageContent')}}
        </p>
      </div>
      <div class="container w1200">
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon4.svg" alt="">
          <p>{{$t('womenCare')}}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon5.svg" alt="">
          <p>{{$t('tcmCare')}}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon2.svg" alt="">
          <p>{{$t('customizeCare')}}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon3.svg" alt="">
          <p>{{$t('psyCare')}}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon7.svg" alt="">
          <p>{{$t('technologyCare')}}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon1.svg" alt="">
          <p>{{$t('vipCare')}}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon6.svg" alt="">
          <p>{{$t('labsCare')}}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon8.svg" alt="">
          <p>{{$t('femTech')}}</p>
        </div>
      </div>
    </section>

    <section id="location">
      <div class="container w1400">
        <div class="txt-center">
          <!-- <h3 class="eng">NUWA LOCATION</h3> -->
          <h5 class="txt-bold">{{$t('location')}} <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower"></h5>
        </div>

        <div class="location_box">
          <div class="pic">
            <swiper :options="swiperOption" v-if="picSet.length">
              <swiper-slide v-for="(item, key) in picSet" :key="key">
                <figure
                  :style="{backgroundImage: `url(${item})`}">
                </figure>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="text">
            <div>
              <div class="clinic_box">
                <h5 class="h5-25 txt-bold">{{$t('nuwaIvf')}}</h5>
                <div class="line"></div>
                <p class="small" style="margin-bottom: 10px;">{{$t('ivfAddress')}}</p>
                <p class="small txt-dark_gray" style="margin-bottom: 5px;">({{$t('ivfLocation')}})</p>
                <p class="small txt-dark_gray" style="margin-bottom: 5px;">{{$t('openTime')}}</p>
                <table>
                  <tr>
                    <td>{{$t('monday')}}～ {{$t('thursday')}}<br>{{$t('friday')}}<br>{{$t('saturday')}}</td><td>：<br>：<br>：</td><td>
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('am')}}
                      </template>
                      08:00 ~
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('night')}}
                      </template>
                      21:00
                      <br>
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('am')}}
                      </template>
                      08:00 ~
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('pm')}}
                      </template>
                      17:00
                      <br>
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('am')}}
                      </template>
                      08:00 ~
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('noon')}}
                      </template>
                      12:00
                      </td>
                  </tr>
                </table>
              </div>
              <br>
              <div class="clinic_box">
                <h5 class="h5-25 txt-bold">{{$t('nuwaTcm')}}</h5>
                <div class="line"></div>
                <p class="small" style="margin-bottom: 10px;">{{$t('tcmAddress')}}</p>
                <p class="small txt-dark_gray" style="margin-bottom: 5px;">({{$t('tcmLocation')}})</p>
                <p class="small txt-dark_gray" style="margin-bottom: 5px;">{{$t('openTime')}}</p>
                <table>
                  <tr>
                    <td>{{$t('monday')}}～ {{$t('thursday')}}<br>{{$t('friday')}}<br>{{$t('saturday')}}</td><td>：<br>：<br>：</td><td>
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('am')}}
                      </template>
                      08:00 ~
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('night')}}
                      </template>
                      21:00
                      <br>
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('am')}}
                      </template>
                      08:00 ~
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('pm')}}
                      </template>
                      17:00
                      <br>
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('am')}}
                      </template>
                      08:00 ~
                      <template v-if="getLanguage !== 'en-US'">
                        {{$t('noon')}}
                      </template>
                      12:00
                      </td>
                  </tr>
                </table>
              </div>
              <br>
              <div class="clinic_box">
                <h5 class="h5-25 txt-bold">{{$t('parking')}}</h5>
                <div class="line"></div>
                <p class="small" style="margin-bottom: 10px;">{{$t('parkingLocation')}}</p>
                <!-- 新增 -->
                <p class="small" style="margin-bottom: 10px;">{{$t('parkingLocation_2')}}</p>
                <p class="small" style="margin-bottom: 10px;">{{$t('parkingLocation_3')}}</p>
              </div>
              <div class="links">
                <a href="tel:0227065288">
                  <img src="@/assets/img/About_NUWA/phone1.svg" alt="">
                </a>
                <a href="mailto:ivfconsult@nuwacare.com">
                  <img src="@/assets/img/About_NUWA/phone2.svg" alt="">
                </a>
              </div>
            </div>
            <div class="txt-right">
              <div class="next">
                <!-- <p class="txt-theme">
                  NEXT
                </p> -->
                <!-- <div class="next_pic">
                  <figure
                    :style="{
                      backgroundImage: `url(${require('@/assets/img/index/imglink_a_update.jpg')})`
                    }">
                  </figure>
                  <div class="cover">
                    <p class="txt-white small">
                      宜蘊中醫診所
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="ready"
      :style="{backgroundImage: `url(${require('@/assets/img/About_NUWA/bg.jpg')})`}">
      <div class="text txt-white txt-center">
        <h3 class="eng">{{$t('endText')}}</h3>
        <h5>WE ARE READY<img src="@/assets/img/deco_flower_white.svg" alt="" class="deco_flower"></h5>
        <div class="btn btn-white"
        :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
        @click="clickHandler()">{{$t('bookingNow')}}</div>
      </div>
    </section>
  </div>
</template>

<script>
import { getLocationPic } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

import '@/assets/scss/about.scss';
import BannerKv from '@/components/BannerKv.vue';
import pic01 from '@/assets/img/about_nuwa_bn_web.jpg';
import pic02 from '@/assets/img/about_nuwa_bn_mob.jpg';
// import pic02 from '@/assets/img/index/about_kv.jpg';

export default {
  name: 'aboutNuwa',
  components: {
    BannerKv,
  },
  data() {
    return {
      npsp: '&nbsp',
      BannerKvData: {
        titleZh: this.$t('aboutNuwa'),
        titleEn: 'ABOUT NUWA',
        backgroundPic: pic01,
        backgroundPicRwd: pic02,
      },
      swiperOption: {
        autoplay: true,
        speed: 600,
        delay: 5000,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '#location .swiper-pagination',
          clickable: true,
        },
      },
      picSet: [],
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
      'getLanguage',
    ]),
  },
  methods: {
    clickHandler() {
      this.$router.push({ path: '/registration' });
    },
    ...mapMutations([
      'setLangUUID',
    ]),
    getData() {
      getLocationPic(
        this.getLangUUID,
        (response) => {
          this.picSet = [];
          response.data.data.forEach((element) => {
            this.picSet.push(element.image_pc);
          });
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getData();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getData();
    }
  },
};
</script>
